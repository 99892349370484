import React, { useState } from 'react';
import Web3 from 'web3';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import ContractABI from '../ABI/ContractABI.json';
import '../styles/Dashboard.css';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [web3, setWeb3] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const contractAddress = '0xD4757bC3e8907cd9eE2EB0DABc46dd8ED97B41D6';

  const connectWallet = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3Instance = new Web3(window.ethereum);
      const accounts = await web3Instance.eth.getAccounts();
      const userAddress = accounts[0];
      const contract = new web3Instance.eth.Contract(ContractABI, contractAddress);
      const contractOwner = await contract.methods.owner().call();
      const isAdminUser = await contract.methods.isController(userAddress);
      console.log('Is admin user:', isAdminUser);
      if (!isAdminUser) {
        setIsAdmin(true);
        alert('Only admin can access! Sorry!');
        return;
      }
      setWeb3(web3Instance);
      setAccount(userAddress);
      setIsConnected(true);
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };

  const disconnectWallet = () => {
    setAccount('');
    setIsConnected(false);
  };

  // Demo data
  const overviewData = {
    projects: 32,
    members: 27,
    liveProject: 23,
    pending: 3,
  };

  const projectRoadmapData = [
    { name: 'Planning', start: 0, duration: 2 },
    { name: 'Research', start: 1, duration: 3 },
    { name: 'Design', start: 2, duration: 4 },
    { name: 'Review', start: 5, duration: 2 },
    { name: 'Develop', start: 6, duration: 3 },
    { name: 'Review II', start: 8, duration: 2 },
  ];

  const issuesData = [
    { name: 'Product design', value: 78 },
    { name: 'Development', value: 63 },
    { name: 'QA & Testing', value: 56 },
    { name: 'Customer queries', value: 36 },
    { name: 'R & D', value: 24 },
  ];

  const projectProgressData = [
    { date: '25 Sep', open: 20, issues: 5, inProgress: 10 },
    { date: '1 Oct', open: 15, issues: 3, inProgress: 12 },
    { date: '7 Oct', open: 10, issues: 2, inProgress: 8 },
  ];

  const todoList = [
    { id: 1, task: 'Designing the dungeon', dueDate: '12 Nov, 2021 12:00 PM', status: 'DRAFT', assignees: 2 },
    { id: 2, task: 'Hiring a motion graphic designer', dueDate: '12 Nov, 2021 12:00 PM', status: 'URGENT', assignees: 3 },
    { id: 3, task: 'Daily Meetings Purpose, participants', dueDate: '12 Dec, 2021 05:00 AM', status: 'ON PROCESS', assignees: 4 },
    { id: 4, task: 'Finalizing the geometric shapes', dueDate: '12 Nov, 2021 12:00 PM', status: 'DRAFT', assignees: 3 },
    { id: 5, task: 'Daily meeting with team members', dueDate: '1 Nov, 2021 12:00 PM', status: 'DRAFT', assignees: 1 },
  ];

  const activityList = [
    {
      date: '01 DEC, 2023',
      time: '10:30 AM',
      title: 'Phoenix Template: Unleashing Creative Possibilities',
      author: 'Shantinon Mekalan',
      description: 'Discover limitless creativity with the Phoenix template! Our latest update offers an array of innovative features and design options.'
    },
    {
      date: '05 DEC, 2023',
      time: '12:30 AM',
      title: 'Empower Your Digital Presence: The Phoenix Template Unveiled',
      author: 'Bookworm22',
      description: 'Unveiling the Phoenix template, a game-changer for your digital presence. With its powerful features and sleek design,'
    },
    {
      date: '15 DEC, 2023',
      time: '2:30 AM',
      title: 'Phoenix Template: Simplified Design, Maximum Impact',
      author: 'Sharuka Nijibum',
      description: 'Introducing the Phoenix template, where simplified design meets maximum impact. Elevate your digital presence with its sleek and intuitive features.'
    }
  ];

  const projectsList = [
    { name: 'Making the Butterflies shoot each other dead', assignees: 4, startDate: 'Dec 12, 2018', deadline: 'Dec 12, 2026', calculation: '$4', progress: 75 },
    { name: 'Project Doughnut Dungeon', assignees: 1, startDate: 'Jan 9, 2019', deadline: 'Dec 9, 2022', calculation: '-', progress: 60 },
    { name: 'The Chewing Gum Attack', assignees: 2, startDate: 'Sep 4, 2019', deadline: 'Dec 4, 2021', calculation: '$657k', progress: 90 },
    { name: 'Execution of Micky the foul mouse', assignees: 3, startDate: 'Nov 1, 2019', deadline: 'Dec 1, 2024', calculation: '-', progress: 30 },
    { name: 'Harnessing stupidity from Jerry', assignees: 3, startDate: 'Dec 28, 2019', deadline: 'Nov 28, 2021', calculation: '-', progress: 45 },
  ];

  const projectsData = [
    { name: 'Metaverse Platform', progress: 65, status: 'In Progress', team: 'Tech Squad', deadline: '2024-06-30' },
    { name: 'ImpactX Centers', progress: 40, status: 'Planning', team: 'Operations', deadline: '2024-12-31' },
    { name: 'Blockchain Consultancy', progress: 80, status: 'Near Completion', team: 'Blockchain Experts', deadline: '2024-03-31' },
    { name: 'VR Tournament Platform', progress: 25, status: 'Early Development', team: 'VR Team', deadline: '2025-01-15' },
    { name: 'Community Engagement App', progress: 50, status: 'In Progress', team: 'Community Team', deadline: '2024-09-30' },
  ];

  const financialsData = {
    revenue: [
      { month: 'Jan', amount: 50000 },
      { month: 'Feb', amount: 65000 },
      { month: 'Mar', amount: 55000 },
      { month: 'Apr', amount: 70000 },
      { month: 'May', amount: 85000 },
      { month: 'Jun', amount: 100000 },
    ],
    expenses: [
      { category: 'Salaries', amount: 150000 },
      { category: 'Marketing', amount: 50000 },
      { category: 'Technology', amount: 75000 },
      { category: 'Operations', amount: 100000 },
      { category: 'Miscellaneous', amount: 25000 },
    ],
    tokenMetrics: {
      circulatingSupply: 10000000,
      totalSupply: 50000000,
      marketCap: 25000000,
      currentPrice: 2.5,
    },
  };

  const membersData = [
    { id: 1, name: 'Alice Johnson', role: 'Lead Developer', joinDate: '2023-01-15', contributionScore: 95 },
    { id: 2, name: 'Bob Smith', role: 'Community Manager', joinDate: '2023-02-01', contributionScore: 88 },
    { id: 3, name: 'Charlie Brown', role: 'Blockchain Specialist', joinDate: '2023-03-10', contributionScore: 92 },
    { id: 4, name: 'Diana Ross', role: 'UX Designer', joinDate: '2023-04-05', contributionScore: 85 },
    { id: 5, name: 'Ethan Hunt', role: 'Marketing Lead', joinDate: '2023-05-20', contributionScore: 90 },
  ];

  const resourcesData = [
    { type: 'Document', name: 'ImpactX Whitepaper', author: 'Core Team', dateAdded: '2023-06-01', downloads: 1250 },
    { type: 'Video', name: 'Introduction to Blockchain', author: 'Charlie Brown', dateAdded: '2023-07-15', views: 3500 },
    { type: 'Presentation', name: 'Q2 2023 Roadmap', author: 'Alice Johnson', dateAdded: '2023-04-01', downloads: 875 },
    { type: 'Tutorial', name: 'Setting Up Your ImpactX Wallet', author: 'Bob Smith', dateAdded: '2023-08-10', views: 2100 },
    { type: 'Research Paper', name: 'VR in Education', author: 'Diana Ross', dateAdded: '2023-09-05', downloads: 620 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  return (
    <div className="dashboard">
      <header>
        <h1>Backoffice Dashboard</h1>
        
      {isConnected ? (
        <div className="header-right">
          <button className="connect-wallet" onClick={disconnectWallet}> <span class="glyphicon log-out glyphicon-log-out"> </span>
            Logout
          </button>
        </div>
      ) : (
        <div className="header-right">
          <button className="connect-wallet" onClick={connectWallet}> <span className='glyphicon log-in glyphicon-log-in'></span>
            Connect Wallet
          </button>
        </div>
      )}
      </header>

      <nav>
        <button onClick={() => setActiveTab('overview')} className={activeTab === 'overview' ? 'active' : ''}>Overview</button>
        <button onClick={() => setActiveTab('projects')} className={activeTab === 'projects' ? 'active' : ''}>Projects</button>
        <button onClick={() => setActiveTab('financials')} className={activeTab === 'financials' ? 'active' : ''}>Financials</button>
        <button onClick={() => setActiveTab('members')} className={activeTab === 'members' ? 'active' : ''}>Members</button>
        <button onClick={() => setActiveTab('resources')} className={activeTab === 'resources' ? 'active' : ''}>Resources</button>
      </nav>

      <main>
        {activeTab === 'overview' && (
          <>
            <section className="overview-stats">
              <div className="stat-card">
                <i className="fas fa-project-diagram"></i>
                <h3>{overviewData.projects}</h3>
                <p>Total Projects</p>
              </div>
              <div className="stat-card">
                <i className="fas fa-users"></i>
                <h3>{overviewData.members}</h3>
                <p>Members</p>
              </div>
              <div className="stat-card">
                <i className="fas fa-piggy-bank"></i>
                <h3>$ {overviewData.liveProject}</h3>
                <p>Total Invest</p>
              </div>
              <div className="stat-card">
                <i className="fas fa-hand-holding-usd"></i>
                <h3>$ {overviewData.pending}</h3>
                <p>Total Revenue</p>
              </div>
            </section>

            <section className="project-roadmap">
              <h2>Kasu Roadmap</h2>
              <p>Phase 2 is now ongoing</p>
              <div className="roadmap-chart">
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={projectRoadmapData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="duration" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </section>

            <section className="issues-discovered">
              <h2>Issues Discovered</h2>
              <p>Newly found and yet to be solved</p>
              <div className="issues-chart">
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={issuesData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {issuesData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </section>

            <section className="project-progress">
              <h2>Project: Eleven Progress</h2>
              <p>Deadline & progress</p>
              <div className="progress-chart">
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={projectProgressData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="open" stroke="#8884d8" />
                    <Line type="monotone" dataKey="issues" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="inProgress" stroke="#ffc658" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </section>

            <div className="flex-container">
              <section className="todo-list">
                <h2>To do</h2>
                <p>Task assigned to me</p>
                <div className="todo-header">
                  <input type="text" placeholder="Search tasks" />
                  <div className="todo-stats">
                    <span>23 tasks</span>
                    <span className="sorting">Sorting</span>
                  </div>
                </div>
                <ul>
                  {todoList.map(task => (
                    <li key={task.id}>
                      <input type="checkbox" />
                      <div>
                        <h4>{task.task}</h4>
                        <p>
                          <i className="fas fa-user-friends"></i> {task.assignees}
                          <i className="fas fa-calendar-alt"></i> {task.dueDate}
                        </p>
                      </div>
                      <span className={`status ${task.status.toLowerCase().replace(' ', '-')}`}>{task.status}</span>
                    </li>
                  ))}
                </ul>
                <button className="add-task">+ Add new task</button>
              </section>

              <section className="activity-list">
                <h2>Activity</h2>
                <p>Recent activity across all projects</p>
                <ul>
                  {activityList.map((activity, index) => (
                    <li key={index}>
                      <div className="activity-date">
                        <p>{activity.date}</p>
                        <p>{activity.time}</p>
                      </div>
                      <div className="activity-content">
                        <h4>{activity.title}</h4>
                        <p>by {activity.author}</p>
                        <p>{activity.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
            </div>
          </>
        )}

        {activeTab === 'projects' && (
          <section className="projects">
            <h2>Active Projects</h2>
            <table className="projects-table">
              <thead>
                <tr>
                  <th>Project Name</th>
                  <th>Progress</th>
                  <th>Status</th>
                  <th>Team</th>
                  <th>Deadline</th>
                </tr>
              </thead>
              <tbody>
                {projectsData.map((project, index) => (
                  <tr key={index}>
                    <td>{project.name}</td>
                    <td>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `${project.progress}%` }}></div>
                      </div>
                      {project.progress}%
                    </td>
                    <td>{project.status}</td>
                    <td>{project.team}</td>
                    <td>{project.deadline}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        )}

        {activeTab === 'financials' && (
          <section className="financials">
            <h2>Financial Overview</h2>
            <div className="financial-charts">
              <div className="revenue-chart">
                <h3>Monthly Revenue</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={financialsData.revenue}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="amount" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="expenses-chart">
                <h3>Expenses Breakdown</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={financialsData.expenses}
                      dataKey="amount"
                      nameKey="category"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#8884d8"
                      label
                    >
                      {financialsData.expenses.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="token-metrics">
              <h3>Token Metrics</h3>
              <ul>
                <li>Circulating Supply: {financialsData.tokenMetrics.circulatingSupply.toLocaleString()}</li>
                <li>Total Supply: {financialsData.tokenMetrics.totalSupply.toLocaleString()}</li>
                <li>Market Cap: ${financialsData.tokenMetrics.marketCap.toLocaleString()}</li>
                <li>Current Price: ${financialsData.tokenMetrics.currentPrice.toFixed(2)}</li>
              </ul>
            </div>
          </section>
        )}

        {activeTab === 'members' && (
          <section className="members">
            <h2>Team Members</h2>
            <table className="members-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Join Date</th>
                  <th>Contribution Score</th>
                </tr>
              </thead>
              <tbody>
                {membersData.map((member) => (
                  <tr key={member.id}>
                    <td>{member.name}</td>
                    <td>{member.role}</td>
                    <td>{member.joinDate}</td>
                    <td>
                      <div className="contribution-score">
                        <div className="score-bar" style={{ width: `${member.contributionScore}%` }}></div>
                        <span>{member.contributionScore}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        )}

        {activeTab === 'resources' && (
          <section className="resources">
            <h2>Learning Resources</h2>
            <table className="resources-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Author</th>
                  <th>Date Added</th>
                  <th>Engagement</th>
                </tr>
              </thead>
              <tbody>
                {resourcesData.map((resource, index) => (
                  <tr key={index}>
                    <td>{resource.type}</td>
                    <td>{resource.name}</td>
                    <td>{resource.author}</td>
                    <td>{resource.dateAdded}</td>
                    <td>
                      {resource.downloads ? `${resource.downloads} downloads` : `${resource.views} views`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        )}
      </main>
    </div>
  );
};

export default Dashboard;